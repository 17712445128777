.dropdown-container {
	position: relative;
	.dropdown-contents {
		position: absolute;
		top: auto;
		bottom: 0;
		display: flex;
		flex-direction: column;
		align-items: baseline;
		background-color: $white;
		box-shadow: 0px 2px 6px $shadow;
		left: 0;
		padding: 1em;
		z-index: 0;
		opacity: 0;
		transition: all .2s ease-out;
		pointer-events: none;
	}

	.dropdown-contents.dropdown-right {
		left: 0;
		right: unset;
	}

	.dropdown-item {
		margin-bottom: 1em;
		white-space: nowrap;
		cursor: pointer;
		border-bottom: none;
		.fal {
			margin-right: .5em;
		}

	}

	.dropdown-item:hover {

		span {
			text-decoration: underline;
		}

	}

	.dropdown-item:last-child {
		margin-bottom: 0;
	}

	.dropdown-contents.height-dropdown {
		top: unset;
		bottom: 50%;
	}

}

.dropdown-contents {
	border-radius: 4px;

	&.horizontal {
		gap: 1rem;

		.dropdown-toggler {
			flex-direction: row;

			i {
				margin-top: 0.3rem;
			}
		}
	}
}

.dropdown-container.active {

	> .dropdown-contents {
		top: auto;
		bottom: 0;
		left: calc(100% + 10px);
		z-index: 1;
		opacity: 1;
		pointer-events: all;
		background: $fog-grey;
	}

	> .dropdown-contents.dropdown-top {
		top: calc(-100% - 10px);
	}

	> .dropdown-contents.height-dropdown {
		top: unset;
		bottom: calc(100% + 10px);
	}

}

.dropdown-item {

	* {
		color: $dark-grey;
		font-weight: 400;
	}

}

.dropdown-item .disabled,
.dropdown-item.disabled * {
	color: $white;
	pointer-events: none;
	cursor: initial;
}

.dropdown-toggler {
	cursor: pointer;
	background: none;
	color: $dark-grey;
	outline: none;
}